body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e6dfdf;
  margin: 1em;
}

h1  {
  font-family: sans-serif;
  font-size: 2em;
}
h2 {
  font-family: sans-serif;
  font-size: 1.25em;
}
p {
  font-family: sans-serif;
  font-size: 1em;
  background-color: #e6dfdf;
}
li, ul {
  color: #6117d8;
  background-color: #e6dfdf;
  font-family: sans-serif;
}

ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
  font-size: 1.75em;
  padding: 0 .35em 0 0;
}
ul.header {
  background-color: #e6dfdf;
  padding: 0;
}
ul.header li a {
  color: #000000;
  /*font-weight: bold;*/
  /*text-decoration: none;*/
  padding: 2px;
  display: inline-block;
}
.content {
  background-color: #e6dfdf;
  padding: 2px;
}
.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
}

.content a {
  font-family: sans-serif;
  font-size: 1em;
}
/* Specific styles for non-active links */
.link {
  color: white;
  text-decoration: none;
}

/* Specific styles for active links */
.link-active {
  font-weight: bold;
  text-decoration: underline;
  color: black;
  background-color: #e6dfdf;
}
